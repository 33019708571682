
import { Options, Vue } from 'vue-class-component';
import dayjs from 'dayjs';
import vueFilePond from 'vue-filepond';
import { FilePondErrorDescription, FilePondFile } from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { codeableConceptCategories, FEATURES, UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import { BaseIcon, BaseSelect, BaseButton, BaseTextInput, BaseModal, BaseDatePicker } from '@/lib/components';
import { Clinic, ConsultType, ExternalPatientReference, BPMLocation, Patient, User } from '@/models';
import { EncounterParticipant } from '@/models/episode-of-care/encounter.model';
import {
  CodeableConceptService,
  OrganisationClinicService,
  OrganisationLocationService,
  OrganisationPatientService,
  OrganisationUserService
} from '@/services/api';
import { EncounterService } from '@/services/api/encounter.service';
import { CodeableConcept, IOption } from '@/lib';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { useNotificationStore } from '@/stores/notification.store';
import { i18n } from '@/i18n/i18n';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

@Options({
  props: {
    title: {
      type: String,
      default() {
        return i18n.global.t('custom.uhb.consult.assign');
      }
    },
    clinicConfigurationId: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    clinicTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BaseIcon,
    BaseDatePicker,
    BaseModal,
    BaseButton,
    BaseSelect,
    BaseTextInput,
    FilePond
  }
})
export default class CreateConsultModal extends Vue {
  clinicConfigurationId!: string;
  patientId!: string;
  loading = false;
  sessionStore = useSessionStore();
  patientStore = usePatientStore();

  scheduled_at = '';
  scheduled_location = '';
  encounterService = new EncounterService();
  codeableConceptService = new CodeableConceptService();
  selectedPatient: Patient | null = null;
  errors: { [key: string]: string[] } = {};

  // Consult types
  consultTypeOptions: IOption[] = [];
  consultTypeId = '';

  // Clinic
  clinicsForConfiguration: Clinic[] = [];
  clinicId = '';

  // Location
  locations: BPMLocation[] = [];
  locationId: string | null = null;

  // File Attachment
  fileAttachmentId: number | null = null;

  // Reviewer
  reviewerId = '';
  reviewerOptions: IOption[] = [];

  // Participants Types
  participantTypes: CodeableConcept[] = [];

  // Statuses
  statuses: CodeableConcept[] = [];

  // Encounter Class
  encounterClasses: CodeableConcept[] = [];

  notificationStore = useNotificationStore();

  get orgPatientService(): OrganisationPatientService {
    return new OrganisationPatientService(this.organisationId);
  }

  get orgUserService(): OrganisationUserService {
    return new OrganisationUserService(this.organisationId);
  }

  get clinicService(): OrganisationClinicService {
    return new OrganisationClinicService(this.organisationId);
  }

  get locationService(): OrganisationLocationService {
    return new OrganisationLocationService(this.organisationId);
  }

  get locationFeatureFlag() {
    return FEATURES.ORGANISATION_LOCATIONS;
  }

  get isLocationFeatureEnabled(): boolean {
    return isFeatureFlagEnabled(this.locationFeatureFlag);
  }

  get nhsNumber() {
    const externalReferences = this.selectedPatientExternalReferences;

    // Find the NHS External reference
    const nhsExternalReference = externalReferences.find(
      (externalReference) => externalReference.type && externalReference.type.key === UHB_NHS_NUMBER_KEY
    );

    // Format it if we found it
    if (nhsExternalReference && nhsExternalReference.value) {
      return formattedNHSNumber(nhsExternalReference.value);
    }

    // Default if not
    return this.$t('platform.common.none');
  }

  get selectedPatientExternalReferences(): Array<ExternalPatientReference> {
    if (this.selectedPatient && this.selectedPatient.external_patient_references) {
      return this.selectedPatient.external_patient_references;
    }

    return [];
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get validateSelection(): boolean {
    return !!(this.clinicId && this.clinicConfigurationId && this.consultTypeId);
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  get clinicOptions(): Array<IOption> {
    return this.clinicsForConfiguration
      .filter((clinic) => clinic.provider && clinic.provider.name)
      .map((clinic) => ({
        value: clinic.id,
        label: (clinic.provider && clinic.provider.name) || ''
      }));
  }

  get locationOptions(): Array<IOption> {
    return this.locations.map((location) => ({
      value: location.id,
      label: location.name
    }));
  }

  get server() {
    return {
      url: `${process.env.VUE_APP_LARAVEL_API_URI}/v1/files`,
      process: '/',
      revert: {
        url: '/',
        headers: {
          'Content-type': 'application/json'
        }
      }
    };
  }

  async mounted() {
    // A clinic owner can create and assign a new consult to a clinic provider.
    this.fetchPatientDetail();
    this.fetchReviewers();
    this.fetchConsultTypes();

    this.participantTypes = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.participantType,
      this.$t('custom.uhb.episode-of-care.participant-type-fetch-error')
    );

    this.statuses = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.encounterStatus,
      this.$t('custom.uhb.episode-of-care.status-code-fetch-error')
    );

    this.encounterClasses = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.encounterClass,
      this.$t('custom.uhb.episode-of-care.encounter-class-fetch-error')
    );

    // Fetch clinics if configuration ID already set
    if (this.clinicConfigurationId) {
      this.fetchClinicsForConfiguration(this.clinicConfigurationId);
    }

    this.$watch('clinicConfigurationId', (value) => {
      this.clinicId = '';
      if (value === '') {
        this.clinicsForConfiguration = [];
      } else {
        this.fetchClinicsForConfiguration(value);
      }
    });

    // Fetch Locations
    if (isFeatureFlagEnabled(FEATURES.ORGANISATION_LOCATIONS)) {
      this.fetchLocations();
    }
  }

  async fetchClinicsForConfiguration(clinicalConfigurationId: string) {
    try {
      this.loading = true;
      this.clinicsForConfiguration = (
        await this.clinicService.index({
          params: {
            'filter[clinic_configuration_id]': clinicalConfigurationId
          }
        })
      ).data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async fetchLocations() {
    try {
      this.loading = true;
      this.locations = (await this.locationService.index()).data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.fetch-locations-error')
      });
    } finally {
      this.loading = false;
    }
  }

  async fetchReviewers() {
    try {
      this.loading = true;
      const reviewers = (
        await this.orgUserService.index({
          params: {
            permission: 'review:create'
          }
        })
      ).data;
      this.reviewerOptions = reviewers.map((user: User) => ({
        label: `${user.given_name} ${user.family_name}`,
        value: user.id
      }));
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response?.data?.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error'),
          label: error.response?.data?.message || ''
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async fetchPatientDetail() {
    try {
      this.selectedPatient = await this.orgPatientService.fetch(this.patientId);
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchConsultTypes() {
    try {
      this.loading = true;
      const consultTypes = (await this.encounterService.getConsultTypes()).data;
      this.consultTypeOptions = consultTypes.map((consultType: ConsultType) => ({
        value: consultType.id,
        label: consultType.name
      }));
    } catch (error) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.consult-type-fetch-error'),
        label: error.response?.data?.message || ''
      });
    } finally {
      this.loading = false;
    }
  }

  async fetchCodeableConceptsByCategory(category: string, errorMessage: string) {
    let codeableConcepts;
    try {
      this.loading = true;
      codeableConcepts = await this.getCodeableConceptByCategory(category);
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: errorMessage,
        label: error.response?.data?.message || ''
      });
    } finally {
      this.loading = false;
    }

    return codeableConcepts;
  }

  close() {
    this.$emit('close', false);
  }

  async createConsult() {
    try {
      this.loading = true;

      // Set review participant if they exist
      const participants: Partial<EncounterParticipant>[] = [];
      if (this.reviewerId) {
        const reviewParticipantType = this.findCodeableConceptByCode('REV', this.participantTypes);
        participants.push({
          user_id: this.reviewerId,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        });
      }

      const createdStatus = this.findCodeableConceptByCode('planned', this.statuses);
      const encounterClass = this.findCodeableConceptByCode('AMB', this.encounterClasses);

      const data = {
        patient_id: this.patientId,
        encounter_class_id: encounterClass?.id,
        clinic_id: this.clinicId,
        organisational_unit_id: this.organisationId,
        location_id: this.locationId,
        participants,
        consult_type_id: this.consultTypeId,
        status_id: createdStatus?.id,
        file_attachment_id: this.fileAttachmentId
      };
      await this.encounterService.create(data);
      this.$emit('close', true);
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response?.data?.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.create-error'),
          label: error.response?.data?.message || ''
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async getCodeableConceptByCategory(category: string): Promise<CodeableConcept[]> {
    return (
      await this.codeableConceptService.index({
        params: {
          'filter[category]': category
        }
      })
    ).data;
  }

  findCodeableConceptByCode(code: string, codeableConcepts: CodeableConcept[]) {
    return codeableConcepts.find((codableConcept) => codableConcept.code === code);
  }

  handleProcessFile(error: FilePondErrorDescription, file: FilePondFile) {
    if (!error) {
      this.fileAttachmentId = JSON.parse(file.serverId).data.id;
    }
  }

  removeFileAttachment() {
    this.fileAttachmentId = null;
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }
}
